const randStr = len => {
  const HexDigits = '0123456789abcdef'
  const HexLength = HexDigits.length

  return Array(len)
    .fill(0)
    .map(_ => HexDigits.substr(Math.floor(Math.random() * HexLength), 1))
    .join('')
}

const replaceAll = (theStr, oldStr, newStr) => theStr.replace(new RegExp(oldStr, 'gm'), newStr)

export { randStr, replaceAll }
