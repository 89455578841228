import React, { useEffect } from 'react'

function Index() {
  useEffect(() => {
    window.location.href = 'https://work.weixin.qq.com/kfid/kfc560a6e94de86e408'
  }, [])

  return <div />
}

export default Index
