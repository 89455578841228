import { combineReducers, legacy_createStore as createStore } from 'redux'
import { reducers as app } from './app'

// root Reducer
const rootReducer = combineReducers({
  app,
})

// store
const configureStore = () => createStore(rootReducer)
const store = configureStore()

export { store }
