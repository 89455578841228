import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Form, Image, Input, Steps, Toast } from 'antd-mobile'
import { apiQueryDelivery } from '../api/delivery'
import imgBanner from '../assets/banner.jpg'

function Index() {
  const [recvPhone, setRecvPhone] = useState('')
  const [deliveries, setDeliveries] = useState([])

  // nav
  const navigate = useNavigate()

  const onQuery = () => {
    console.log(recvPhone)
    if (!/^1[3-9]\d{9}$/.test(recvPhone)) {
      Toast.show({
        icon: 'fail',
        content: '收件号码应为11位',
      })
      return
    }

    apiQueryDelivery(recvPhone)
      .then(resp => {
        const data = resp.data
        if (data.code === '1') {
          setDeliveries(data.data)
          if (data.data.length === 0) {
            Toast.show({
              icon: 'success',
              content: '数据为空',
            })
          }
        } else {
          Toast.show({
            icon: 'fail',
            content: data.info,
          })
          setDeliveries([])
        }
      })
      .catch(err => {
        Toast.show({
          icon: 'fail',
          content: err.toString(),
        })
        setDeliveries([])
      })
  }

  return (
    <div style={{ margin: '0' }}>
      <div>
        <Image src={imgBanner} />
      </div>
      <Form
        layout="horizontal"
        footer={
          <Button block color="primary" size="large" onClick={onQuery}>
            查询
          </Button>
        }
      >
        <Form.Item label="收件号码">
          <Input
            value={recvPhone}
            onChange={value => setRecvPhone(value)}
            placeholder="收件号码"
            clearable
            maxLength={11}
            minLength={11}
          />
        </Form.Item>
      </Form>

      {deliveries.length === 0 && recvPhone.length > 0
        ? ''
        : deliveries.length === 0 && recvPhone.length === 0
        ? ''
        : deliveries.map(it => (
            <Card
              key={it.orderId}
              headerStyle={{
                color: '#1677ff',
              }}
              title={`物流：${it.carrierDelivery}`}
            >
              <div>
                <div>申请时间：{it.createTime}</div>
                {it.carrierOpenStatusText === '成功' ? <div>新的号码：{it.carrierOpenPhone || '--'}</div> : null}
                {it.carrierOpenStatusText !== '成功' ? <div>错误提示：{it.carrierOpenStatusText || '--'}</div> : null}
                {it.carrierOpenStatusText === '成功' ? <div>物流信息：{it.carrierDeliveryStatusText || ''}</div> : null}
              </div>

              {it.carrierOpenStatusText === '成功' ? (
                <Steps direction="vertical">
                  {it.tracks.map(x => (
                    <Steps.Step
                      key={x.trackTime}
                      title={x.trackInfo}
                      status="finish"
                      description={`完成时间：${x.trackTime}`}
                    />
                  ))}
                </Steps>
              ) : (
                ''
              )}
            </Card>
          ))}
    </div>
  )
}

export default Index
