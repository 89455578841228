import ajaxApi from '../infra/axios-settings'

const apiBanner = () => {
  return ajaxApi.get('/api/v1/mall/config')
}

const apiProd = () => {
  return ajaxApi.get('/api/v1/mall/prod?shopId=ZH5100A')
}

const apiOrder = payload => {
  return ajaxApi.post('/api/v1/order-shop-2', payload)
}

export { apiBanner, apiProd, apiOrder }
