import axios from 'axios'
import { md5Digest } from '../util/md5'
import { HTTP_URL } from './constants'

const ajaxApi = axios.create({
  baseURL: HTTP_URL.ROOT,
  timeout: 30_000,
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN',
})
ajaxApi.all = axios.all
ajaxApi.spread = axios.spread

ajaxApi.defaults.headers.post['Content-Type'] = 'application/json'

ajaxApi.interceptors.request.use(
  config => {
    const ms = new Date().getTime()
    config.headers.shopId = 'ZH5MALL'
    config.headers.time = '' + ms
    config.headers.sign = md5Digest(`time=${ms}&shopId=ZH5MALL&apiSecret=1577b502-3ddb-48e2-970c-8c697f905b29`)
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

export default ajaxApi
